import React, { Component } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Nav from './Nav';

export default class MenuPopup extends Component {
	render() {
		return (
			<div id="popup-wrapper" className={(this.props.isOpen ? 'open' : '')}>
				<Nav id="popup-nav" isPopup={true} handleMenuClick={this.props.handleMenuClick}></Nav>
			</div>
		)
	}
}
