import { FastField, useFormikContext } from 'formik'
import React, { Fragment } from 'react'

function PersonalInfo() {
  const { errors, touched } = useFormikContext()

  return (
    <Fragment>
      <div id="segment-1" className="segment">
        <div id="personal-info" className="form-group">
          <h3>Applicant Information</h3>

          <label htmlFor="applicantName">Applicant Name</label>
          <FastField type="text" name="applicantName" id="applicantName" />
          <small className="error">
            {touched.applicantName && errors.applicantName}
          </small>

          <div id="address-temp" className="form-group">
            <h4>Applicant Address (Most Recent)</h4>

            <label htmlFor="streetTemp">Street</label>
            <FastField type="text" name="streetTemp" id="streetTemp" />
            <small className="error">
              {touched.streetTemp && errors.streetTemp}
            </small>

            <label htmlFor="cityTemp">City</label>
            <FastField type="text" name="cityTemp" id="cityTemp" />
            <small className="error">
              {touched.cityTemp && errors.cityTemp}
            </small>

            <div className="row-group">
              <div>
              <label htmlFor="stateTemp">State</label>
                <FastField as="select" name="stateTemp" id="stateTemp">
                  <option value="">–</option>
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </FastField>
              <small className="error">
                {touched.stateTemp && errors.stateTemp}
              </small>
              </div>

              <div>
                <label htmlFor="zipTemp">Zip</label>
                <FastField type="text" name="zipTemp" id="zipTemp" />
                <small className="error">
                  {touched.zipTemp && errors.zipTemp}
                </small>
              </div>


            </div>

          </div>
          {/* address-temp END */}


          <div className="t-mar-30"></div>

          <label htmlFor="applicantTimeAtAddress">Length of time at Address</label>
          <FastField type="text" name="applicantTimeAtAddress" id="applicantTimeAtAddress" />
          <small className="error">
            {touched.applicantTimeAtAddress && errors.applicantTimeAtAddress}
          </small>

          <label htmlFor="phoneTemp">Applicant Phone Number</label>
          <FastField type="text" name="phoneTemp" id="phoneTemp" />
          <small className="error">
            {touched.phoneTemp && errors.phoneTemp}
          </small>

          <label htmlFor="applicantEmail">Applicant Email Address</label>
          <FastField type="text" name="applicantEmail" id="applicantEmail" />
          <small className="error">
            {touched.applicantEmail && errors.applicantEmail}
          </small>

          <label htmlFor="ssn">Social Security Number</label>
          <FastField type="text" name="ssn" id="ssn" />
          <small className="error">
            {touched.ssn && errors.ssn}
          </small>

          <label htmlFor="dob">Date of Birth (mm/dd/yy)</label>
          <FastField type="text" name="dob" id="dob" />
          <small className="error">
            {touched.dob && errors.dob}
          </small>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 90px",
              gridColumnGap: "10px"
            }}
          >
            <div>
              <label htmlFor="licenseNumber">Driver’s License Number</label>
              <FastField type="text" name="licenseNumber" id="licenseNumber" />
              <small className="error">
                {touched.licenseNumber && errors.licenseNumber}
              </small>
            </div>
            <div>
              <label htmlFor="licenseState">State of Issue</label>
              <FastField as="select" name="licenseState" id="licenseState">
                <option value="">–</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </FastField>
              <small className="error">
                {touched.licenseState && errors.licenseState}
              </small>
            </div>
          </div>



        </div>
      </div>

    </Fragment>
  )
}

export default PersonalInfo
