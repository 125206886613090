import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class SocialIcons extends Component {
	render() {
		return (
			<div className={this.props.className}>
				<a href="https://www.facebook.com/awaypropertymanagment">
					<FontAwesomeIcon size="2x" icon={['fab', 'facebook-square']} />
				</a>
				<a href="https://www.instagram.com/awaypropertymanagement/">
					<FontAwesomeIcon size="2x" icon={['fab', 'instagram-square']} />
				</a>
				<a href="https://www.linkedin.com/in/away-property-management-5734501a4/">
					<FontAwesomeIcon size="2x" icon={['fab', 'linkedin']} />
				</a>
			</div>
		)
	}
}
