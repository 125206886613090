import { FastField, useFormikContext } from 'formik'
import React, { Fragment } from 'react'

function Acknowledgements() {
	const { errors, touched } = useFormikContext()

	return (
		<Fragment>
			<div id="segment-4" className="segment">
				<div id="acknowledgements" className="form-group">
					<h3>Acknowledgements</h3>
					<p>I certify all of the above information is true and complete. I acknowledge that false information herein may constitute grounds for rejection for this Application, termination of right of occupancy and non-return of fees and deposits.</p>

					<p>I hereby authorize Owner to use reasonable and necessary means, including any credit reporting agency, current and previous employer, current and former landlord, law enforcement agency, any check authorization agency, and state employment security agency, to release all information any of them may have about me. I hereby release all of these parties, including but not limited to the Owner and any agency designated by Owner, from any liability in connection with release of such information. I hereby authorize Owner to obtain and hereby instruct any credit reporting agency designated by Owner to furnish a credit report under The Fair Credit Reporting Act to Owner to use such credit report in attempting to collect any amounts due and owing under the Lease Contract or the Cosigner or for any other permissible purpose.</p>

					<p>I hereby pay to Owner a Non-Refundable Application Fee ($25.00) for processing this Application. I acknowledge the Non-Refundable Application Fee shall not be refunded for any reason.</p>

					<p>If I cannot prove income and/or do not meet the qualifying credit history, I will obtain a Cosigner who will sign Cosigner Form.</p>

				</div> {/* acknowledgements END */}

				<div className="agreement-wrapper">
					<FastField type="checkbox" name="agreement" id="agreement" />
					<label htmlFor="agreement">By submitting this application,  I represent that I have read and agree to all provisions of this Application.</label>
				</div>
				<small className="error">
					{errors.agreement}
					{touched.agreement}
				</small>


			</div>

		</Fragment>
	)
}

export default Acknowledgements
