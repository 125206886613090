import React, { Component } from 'react'
import { ReactComponent as Rings } from '../images/rings.svg'
import { motion } from "framer-motion";

export default class CircleRings extends Component {
	render() {
		return (
			<motion.div
				id={this.props.id}
				className="rings-wrapper"
				initial={{ opacity: 0.9}}
				animate={{ rotate: 360 }}
				transition={{
					loop: Infinity,
					duration: (30 + Math.random() * 20),
					ease: "linear",
					delay: Math.random() * 2
				}}
			>
				<Rings  style={this.props.style}></Rings>
			</motion.div>
		)
	}
}
