import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as AwayLogo } from '../images/away-property-management-logo.svg';
import { ReactComponent as CurveMobile } from '../images/header-curve-mobile.svg';
import { ReactComponent as CurveDesktop } from '../images/header-curve-desktop.svg';
import Nav from './Nav';
import Button from './Button'

export default class Header extends Component {

	render() {

		return (
			<header>
				<div id="mobile-bar"></div>
				<div id="header-curve-desktop-end-block"></div>
				<div className="content-wrapper" >
					<div id="header-logo-wrapper" >
						<CurveMobile id="header-curve-mobile" />
						<CurveDesktop id="header-curve-desktop" />
						<Link to="/">
							<AwayLogo id="top-logo" />
						</Link>
					</div>
					<div id="header-nav-wrapper">
						<Nav id="top-nav"></Nav>
						<Link to="/listings" >
							<Button
								bg_color="black"
								txt_color="white"
								height=""
								icon_name="envelope-open-dollar"
								icon_style="fad"
								label="Pay Rent Online"
							/>
						</Link>
					</div>
				</div>
			</header>
		)
	}
}
