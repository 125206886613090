import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class DLIcon extends Component {
	render() {
		const format = 'file-' + this.props.format;
		const color = (this.props.format === 'pdf') ? '#9F1616' : '#5368B5';
		return (
			<a
				href={process.env.PUBLIC_URL + `/download/forms/${this.props.filename}`}
				className={"download-file-link " + this.props.format}
			>
				<FontAwesomeIcon
					icon={['fad', format]}
					size='lg'
					color={color}
				/>
			</a>
		)
	}
}
