import { FastField, useFormikContext } from 'formik'
import React, { Fragment } from 'react'

function CriminalHistoryEmergency() {
	const { errors, touched } = useFormikContext()

	return (
		<Fragment>
			<div id="segment-3" className="segment">
				<div id="emergency-contact" className="form-group">
					<h3>Emergency Contact Information</h3>
					<label htmlFor="emergencyName">Name of Contact</label>
					<FastField type="text" name="emergencyName" id="emergencyName" />
					<small className="error">
						{touched.emergencyName && errors.emergencyName}
					</small>

					<label htmlFor="emergencyRelationship">Relationship</label>
					<FastField type="text" name="emergencyRelationship" id="emergencyRelationship" />
					<small className="error">
						{touched.emergencyRelationship && errors.emergencyRelationship}
					</small>

					<label htmlFor="emailEmergency">Email Address</label>
					<FastField type="text" name="emailEmergency" id="emailEmergency" />
					<small className="error">
						{touched.emailEmergency && errors.emailEmergency}
					</small>

					<label htmlFor="phoneEmergency">Phone Number</label>
					<FastField type="text" name="phoneEmergency" id="phoneEmergency" />
					<small className="error">
						{touched.phoneEmergency && errors.phoneEmergency}
					</small>

				</div> {/* emergency-contact END */}

				<div id="criminal-background" className="form-group">
					<h3>Criminal Background</h3>
					<p>Have you ever been convicted; plead guilty; plead no contest; received probation, deferred adjudication, court-ordered community supervision or pre-trial diversion for a felony (whether or not resulting in a conviction)?</p>

					<FastField component="div" name="felonyBackground" className="yes-no-wrapper">
						<input
							type="radio"
							id="felonyBackgroundOne"
							name="felonyBackground"
							value="Yes"
						/>
						<label htmlFor="felonyBackgroundOne">Yes</label>

						<input
							type="radio"
							id="felonyBackgroundTwo"
							name="felonyBackground"
							value="No"
						/>
						<label htmlFor="felonyBackgroundTwo">No</label>
					</FastField>

					<p>Have you ever been convicted; plead guilty; plead no contest; received probation, deferred adjudication, court-ordered community supervision or pre-trial diversion for a misdemeanor involving violence or sexual misconduct (whether or not resulting in conviction)?</p>

					<FastField component="div" name="misdemeanorBackground" className="yes-no-wrapper">
						<input
							type="radio"
							id="misdemeanorBackgroundOne"
							name="misdemeanorBackground"
							value="Yes"
						/>
						<label htmlFor="misdemeanorBackgroundOne">Yes</label>

						<input
							type="radio"
							id="misdemeanorBackgroundTwo"
							name="misdemeanorBackground"
							value="No"
						/>
						<label htmlFor="misdemeanorBackgroundTwo">No</label>
					</FastField>
				</div> {/* criminal-background END */}

				<div id="rental-history" className="form-group">
					<h3>Rental History</h3>
					<p>Have you ever been evicted or asked to move out from a previous landlord?</p>
					<FastField component="div" name="evictedHistory" className="yes-no-wrapper">
						<input
							type="radio"
							id="evictedHistoryOne"
							name="evictedHistory"
							value="Yes"
						/>
						<label htmlFor="evictedHistoryOne">Yes</label>

						<input
							type="radio"
							id="evictedHistoryTwo"
							name="evictedHistory"
							value="No"
						/>
						<label htmlFor="evictedHistoryTwo">No</label>
					</FastField>

					<p>	Do you currently owe any outstanding balance (s) to a previous landlord?</p>
					<FastField component="div" name="outstandingBalanceHistory" className="yes-no-wrapper">
						<input
							type="radio"
							id="outstandingBalanceHistoryOne"
							name="outstandingBalanceHistory"
							value="Yes"
						/>
						<label htmlFor="outstandingBalanceHistoryOne">Yes</label>

						<input
							type="radio"
							id="outstandingBalanceHistoryTwo"
							name="outstandingBalanceHistory"
							value="No"
						/>
						<label htmlFor="outstandingBalanceHistoryTwo">No</label>
					</FastField>

				</div> {/* rental-history END */}


			</div>

		</Fragment>
	)
}

export default CriminalHistoryEmergency
