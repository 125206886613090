import React, { useState } from 'react'
import BottomScrollListener from 'react-bottom-scroll-listener';
import Nav from './Nav';
import ContactInfo from './ContactInfo';
import SocialIcons from './SocialIcons'

import { ReactComponent as EHOIcon } from '../images/equal-housing-opportunity-logo.svg';
import { ReactComponent as HouseSVG } from '../images/house-illo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Footer() {
	const [footerReached, setFooterReached] = useState(0);

	const scrollCallback = () => {
		setFooterReached(1);
	}
	return (
		<footer>
			<BottomScrollListener onBottom={scrollCallback}/>
			<div className="content-wrapper">
				<div id="top-block" className="grid-wrapper">
					<Nav id="bottom-nav"></Nav>
					<div className="right-block">
						<ContactInfo />
						<HouseSVG className={"house " + ((footerReached === 1) && "lit")} />
					</div>
				</div>
				<div id="footer-divider"></div>
				<div id="aff-block-wrapper">
					<div className="stay-connected">Stay Connected</div>
					<SocialIcons className="mobile-social" />

					<EHOIcon className="eho-logo" />
					<div id="aff-content-wrapper">
						<div className="copyright footer-small">©2020 Away Property Management</div>
						<div className="all-rights footer-small">All rights reserved.</div>
						<div className="made-with footer-small">Website made with&nbsp;
								<FontAwesomeIcon icon={['fas', 'heart']} />&nbsp;
							&&nbsp;
								<FontAwesomeIcon icon={['fas', 'coffee']} />&nbsp;
								by <a href='https://brightersidestudio.com?ref=awaypropertymanagement' alt="Brighter Side Studio, creative studio based in De Pere, Wisconsin">Brighter Side Studio</a></div>
						<SocialIcons className="full-social" />
					</div>
				</div>

			</div>
		</footer>
	)
}