import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Button extends Component {
	render() {
		const disabled_class = (this.props.disabled) ? ' disabled' : '';
		return (
			<button
				name={this.props.name}
				className={"fa-button" + disabled_class}
				onClick={this.props.loadMore}
				style={{
					"color": this.props.txt_color,
					"backgroundColor": this.props.bg_color,
					"height": this.props.height,
					"lineHeight": this.props.height,
					"fontSize": this.props.font_size
				}}
				aria-label={this.props.label}
				id={this.props.id}
				>
				{this.props.label}
				{/* if icon prop exist, then render FontAwesomeIcon */}
				{this.props.icon_name && <FontAwesomeIcon size="sm" icon={[this.props.icon_style, this.props.icon_name]} /> }
			</button>
		)
	}
}
