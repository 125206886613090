import React, {Fragment} from 'react';

const NotFound = props => (
	<Fragment>
		<h2>Page Not Found!</h2>
		<p><code style={{ color: "#bada55", backgroundColor: "#444", padding: "2px 4px" }}>'{props.location.pathname}'</code> is not a valid page.</p>
	</Fragment>
);

export default NotFound;
