import React, { Component } from 'react'
import CircleHouses from '../images/circle-houses.png'
export default class HomeCircle extends Component {
	render() {
		return (
			<div id={this.props.id} className="circle-houses">
				<img src={CircleHouses} alt=""/>
			</div>
		)
	}
}
