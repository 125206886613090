import React, { Fragment } from 'react';
import FormikWizard from 'formik-wizard';
import applicationFormSteps from './form-sections/applicationFormSteps';
import { Link } from 'react-router-dom'
import Button from './Button'
import CircleRings from './CircleRings'


/* Form submission */
import axios from 'axios';
const API_PATH = 'https://cms.awaypropertymanagement.com/api/application/index.php';
let current_step = 'segment-1'; // Initial state
let last_step = 'segment-1'; // Initial state
const imagePath = process.env.PUBLIC_URL + '/images/application-bg/';
let images = [
	imagePath + 'application-bg-img-1.jpg',
	imagePath + 'application-bg-img-2.jpg',
	imagePath + 'application-bg-img-3.jpg',
	imagePath + 'application-bg-img-4.jpg',
	imagePath + 'application-bg-img-5.jpg',
	imagePath + 'application-bg-img-6.jpg',
	imagePath + 'application-bg-img-7.jpg',
];



const getPropertyAddress = () => {
	const propertyAddressParts = window.location.pathname.split('/');
	const propertyAddressClean = decodeURI(propertyAddressParts[propertyAddressParts.length - 1]);

	/* Manually adding form data to FormikWizard */
	applicationFormSteps[0].initialValues.propertyAddress = propertyAddressClean;
	return propertyAddressClean;
}


const buttonClickHandler = () => {

	setTimeout(function () {
		// console.group();
		// 1. Button clicked, what step are we on?
		const segments = document.getElementsByClassName("segment");
		if(segments.length === 0) return false; // Break code if not a segment page
		const this_step = segments[0].id;
		// console.log(`this_step: ${this_step}`);
		// 1a. Get segment id
		const segment_id = this_step.replace(/\D/g, "");
		// console.log(`segment_id: ${segment_id}`);

		if(segment_id === 5) return false;

		// 1b. Update current_step with this_step
		current_step = this_step; // update current step

		// 2. What was the last step we were on before the click?
		// console.log(`last_step: ${last_step}`);

		// 3. Did the segment change from last step?
		if(this_step !== last_step) {
			console.log('segment changed');
			// Update necessary elements
			for (let i = 1; i < 5; i++) {
				// 1. Get circle by id
				const this_circle = document.getElementById("circle-"+i);
				// 2. Check if id is <= to segment id
				// console.log(`i = ${i}, id = ${segment_id}`);
				if (i <= segment_id) {
					this_circle.classList.add("active");
				} else {
					this_circle.classList.remove("active");
				}
			}

			// Scroll to top of page
			window.scrollTo({ top: 0 });

			// Update last_step after all elements have changed
			last_step = current_step;
		} else {
			console.log('Segment unchanged. No need to update any elements.');
			// No need to update any elements
		}

		// console.group();
		// 	console.log("Handler completed. Recap variables:");
		// 	console.log(`last_step: ${last_step}`);
		// 	console.log(`current_step: ${current_step}`);

		// 	console.groupEnd();
		// console.groupEnd();
	}, 100);
}
function randomDelay() {
	const rand = Math.random() * 3;
	return rand;
}
function shuffle(array) {
	let currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}
function getImages() {
	shuffle(images);
}

function FormWrapper({
	children,
	isLastStep,
	status,
	goToPreviousStep,
	canGoBack,
	actionLabel,
}) {
	if(status) {
		return (
			<div id="submission-complete-wrapper">
				<div className="message">{status.message}</div>
				<div id="return-to-listings-wrapper">
					<Link to="/listings">
						<Button
							label="Return to Listings"
							txt_color="#1d1d1d"
							bg_color="#8cb5d8"
						/>
					</Link>
				</div>
			</div>
		)
	} else {
		return (
			<Fragment>
				<div className="circle-bg-wrapper">
					<CircleRings
						id="rings-application-1"
						delay={randomDelay()}
					/>
					<CircleRings
						id="rings-application-2"
						delay={randomDelay()}
					/>
					<CircleRings
						id="rings-application-3"
						delay={randomDelay()}
					/>
				</div>
				<div className="bg-images-wrapper">
					<img src={images[0]} alt=""/>
					<img src={images[1]} alt=""/>
				</div>
				<div id="segment-tracker"></div>
				{children}

				<div className="form-nav-wrapper">
					<button type="button" onClick={() => {
						goToPreviousStep();
						buttonClickHandler();
					}
					} disabled={!canGoBack}>
						Back
        </button>
				<button type="submit" onClick={buttonClickHandler}>
					{actionLabel || (isLastStep ? 'Submit' : 'Next')}
				</button>
				</div>
			</Fragment>
		)
	}
}

function ApplicationForm() {
	const handleSubmit = React.useCallback((values) => {
		// console.log('full values:', values)
		axios({
			method: 'post',
			url: `${API_PATH}`,
			headers: { 'content-type': 'application/json' },
			data: values
		})
			.then(result => {
				// console.dir(values);
				// console.dir(result);
			})
			.catch(error => console.log(error.message));
			return {
				message: 'Thank you for submitting your application. We will be in contact with you soon.',
			}


	}, [])
	getImages();


	return (
		<Fragment>
			<div id="application-wrapper" className="card form" >
				<h1>Housing Application</h1>
				<div className="property-address">{getPropertyAddress()}</div>

				{/* Progress Bar */}
				<div id="progress-wrapper">
					<div id="circle-1" className="circle active"></div>
					<div id="circle-2" className="circle"></div>
					<div id="circle-3" className="circle"></div>
					<div id="circle-4" className="circle"></div>
					<div className="bar"></div>
				</div>

				<FormikWizard
					steps={applicationFormSteps}
					onSubmit={handleSubmit}
					render={FormWrapper}
				/>

			</div>
		</Fragment>
	)
}

export default ApplicationForm
