import React, { Fragment } from 'react'
import PageHero from './PageHero'
import CircleRings from './CircleRings'
import Mailto from 'react-protected-mailto'
import WorkWithUsHero from '../images/heros/about-us-hero.jpg'
import People from '../images/work-with-us-people.jpg'

export default function WorkWithUs(props) {
	function randomDelay() {
		const rand = Math.random() * 3;
		// console.log(`rand: ${rand}`);
		return rand;
	}
	return (
		<Fragment>
			<PageHero
				bg_color="#fbf9fb"
				txt_color="black"
				height=""
				title="Work With Us"
				source={WorkWithUsHero}
			/>
			<div className="circle-bg-wrapper">
				<CircleRings
					id="rings-about-1"
					delay={randomDelay()}
				/>
				<CircleRings
					id="rings-about-2"
					delay={randomDelay()}
				/>
			</div>
			<div id="main-work-with-content-wrapper" className="main-content-wrapper">
				{/* <CircleRings /> */}
				<div id="main-work-with-content-block-wrapper" className="main-content-block-wrapper">

					<div id="main-wrapper" className="card">
						<img src={People} alt=""/>
						<div className="copy">Please email  your resume to <Mailto
							email='manager@awaypropertymanagement.com'
							headers={
								{ subject: 'Property Management Proposal' }
							}
						/>.</div>
					</div> {/* main-wrapper END */}
				</div> {/* main-work-with-content-block-wrapper END */}
			</div> {/* main-work-with-content-wrapper END */}

		</Fragment>
	)
}
