import React, { Component } from 'react'

export default class PageHero extends Component {
	render() {
		return (
			<div
				className="hero-wrapper"
				style={{ "color": this.props.txt_color, "backgroundColor": this.props.bg_color }}
			>
				<img src={this.props.source} alt="{this.props.title} hero" className="hero-image" />
				<h1>{this.props.title}</h1>
				{/* <FontAwesomeIcon size="2x" icon={[this.props.icon_style, this.props.icon_name]} /> */}
			</div>
		)
	}
}
