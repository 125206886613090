import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ThreeDotsWave from './ThreeDotsWave'
export default function LoadingAnimation() {
	return (
		<div className="page-loader-wrapper">
			<div className="graphics-wrapper">
				<FontAwesomeIcon
					size="3x"
					icon={['fad', 'home-heart']}
					style={{ "color": "#8cb5d8" }}
				/>
				<div className="text">Loading…</div>
				<ThreeDotsWave />
			</div>
		</div>
	)
}
