import React, { Component, Fragment } from 'react'
// import { Link } from 'react-router-dom';
import PageHero from './PageHero'
import CircleRings from './CircleRings'
import DLIcon from './DLIcon'
import FormHero from '../images/heros/forms-hero.jpg'


export default class DownloadForms extends Component {
	randomDelay = () => {
		const rand = Math.random() * 3;
		// console.log(`rand: ${rand}`);
		return rand;
	}
	render() {
		return (
			<Fragment>
					<PageHero
						bg_color="#fbf9fb"
						txt_color="black"
						height=""
						title="Download Forms"
						source={FormHero}
					/>
				<div className="circle-bg-wrapper">
					<CircleRings
						id="rings-download-1"
						delay={this.randomDelay()}
					/>
				</div>
					<div id="main-dl-content-wrapper" className="main-content-wrapper">
						<div id="main-dl-content-block-wrapper" className="main-content-block-wrapper card">
							<div id="sendto-content-block-wrapper">
								Download the paperwork you need below. Please send completed forms to:
								<div id="sendto-content-block-address">
									<div>7235 Hidden Hollow Court</div>
									<div>Mounds View, MN  55112</div>
								</div>
							</div>


							{/*
Standard Housing Application.pdf
Standard Housing Application.docx
Co-Signer Application.docx
Co-Signer Application.pdf
Residential Lease.doc
Residential Lease.pdf

							*/}



							{/* Form files */}
							<div id="main-forms-wrapper">
								<div className="forms-item">
								<label><div className="forms-title">Co-Signer Application</div></label>
									<div className="icons">
										<DLIcon
											filename="Co-Signer Application.pdf"
											format="pdf"
										/>
										<DLIcon
											filename="Co-Signer Application.docx"
											format="word"
										/>
									</div>
								</div>
								<div className="forms-item">
									<label>
									<div className="forms-title">Residential Lease</div>
									</label>
									<div className="icons">
										<DLIcon
											filename="Residential Lease.pdf"
											format="pdf"
										/>
										<DLIcon
											filename="Residential Lease.doc"
											format="word"
										/>
									</div>
								</div>
								<div className="forms-item">
									<label>
										<div className="forms-title">Standard Housing Application</div>
									</label>
									<div className="icons">
										<DLIcon
											filename="Standard Housing Application.pdf"
											format="pdf"
										/>
										<DLIcon
											filename="Standard Housing Application.docx"
											format="word"
										/>
									</div>
								</div>
							</div>
						</div>

					</div>
			</Fragment>
		)
	}
}
