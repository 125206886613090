import React, { Fragment, useState } from 'react'
// import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import PageHero from './PageHero'
import CircleRings from './CircleRings'
// import Button from './Button'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import AboutUsHero from '../images/heros/about-us-hero.jpg'

const API_PATH = 'https://cms.awaypropertymanagement.com/api/contact/index.php';

export default function AboutUs (props) {
	const [toggleState, setToggleState] = useState(false);

	/* Yup validation schema */
	const SignupSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Your first name is required'),
		lastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Your last name is required'),
		email: Yup.string()
			.email('Invalid email')
			.required('Your email address is required'),
	});

	function randomDelay() {
		const rand = Math.random() * 3;
		// console.log(`rand: ${rand}`);
		return rand;
	}
	function toggle() {
		setToggleState(toggleState === false ? true : false);
		// console.log('toggling');
	}
	return (
		<Fragment>
			<PageHero
				bg_color="#fbf9fb"
				txt_color="black"
				height=""
				title="About Us"
				source={AboutUsHero}
			/>
			<div className="circle-bg-wrapper">
				<CircleRings
					id="rings-about-1"
					delay={randomDelay()}
				/>
				<CircleRings
					id="rings-about-2"
					delay={randomDelay()}
				/>
			</div>
			<div id="main-about-content-wrapper" className="main-content-wrapper">
				{/* <CircleRings /> */}
				<div id="main-about-content-block-wrapper" className="main-content-block-wrapper">
					<div id="description-content-block-wrapper">
						<p>We are your one stop shop for all Property Management needs, from helping you find your next investment property, leasing your current investment property, maintenance, financials, and most importantly taking care of your residents. With 16 years of Property Management experience we bring a strong background specializing in residential and student housing. Through our expertise, collaboration, communication, dedication and knowledge we have created a better experience which in return gives our clients high financial results that they are looking to receive.
</p>
					</div>
					<div id="main-contact-wrapper" className="card">
						<h2 id="contact-header">Questions, Comments, or Concerns?</h2>
						<div id="contact-instructions">Use the form below to get in touch. Our office hours are Monday through Friday, 9am to 5pm Central, and we attempt to respond to support requests within 1 business day.</div>

						<div className="form-wrapper"> {/* FORM WRAPPER */}

							<Formik
								initialValues={{
									firstName: '',
									lastName: '',
									email: '',
									phoneNumber: '',
									whenMove: '',
									message: ''
								}}
								validationSchema={SignupSchema}
								onSubmit={(values, { setSubmitting }) => {
									axios({
										method: 'post',
										url: `${API_PATH}`,
										headers: { 'content-type': 'application/json' },
										data: values
									})
										.then(result => {
											// console.log('end');
											// console.dir(values);
											// console.dir(result);
											toggle();
											setTimeout(() => {
												setSubmitting(false);
												document.getElementById("contact-form").reset();
											}, 400);
										})
										.catch(error => console.log(error.message));

								}}
							>
								{({ isSubmitting, errors, touched }) => (
									<Form id="contact-form">
										<div className="form-group">
											<div className="row">
												<div className="field-block">
													<label htmlFor="firstName">First Name</label>
													<Field
														type="text"
														id="firstName"
														name="firstName"
													/>
													{errors.firstName && touched.firstName ? (
														<small className="error">{errors.firstName}</small>
													) : null}
												</div>

												<div className="field-block">
													<label htmlFor="lastName">Last Name</label>
													<Field type="text" id="lastName" name="lastName" />
													{errors.lastName && touched.lastName ? (
														<small className="error">{errors.lastName}</small>
													) : null}
												</div>
											</div>

											<div className="row">
												<div className="field-block">
													<label htmlFor="email">Email Address</label>
													<Field
														type="email"
														id="email"
														name="email"
													/>
													{errors.email && touched.email ? (
														<small className="error">{errors.email}</small>
													) : null}
												</div>

												<div className="field-block">
													<label htmlFor="phoneNumber">Phone Number</label>
													<Field type="text" id="phoneNumber" name="phoneNumber" />
													<ErrorMessage name="phoneNumber" component="div" />
												</div>
											</div>

											<label htmlFor="whenMove">When are you looking to move?</label>
											<Field type="text" id="whenMove" name="whenMove" />
											<ErrorMessage name="whenMove" component="div" />

											<label htmlFor="message">Message</label>
											<Field component="textarea" id="message" name="message" />
											<ErrorMessage name="message" component="div" />

											<div className="button-wrapper">
												<button type="submit" disabled={isSubmitting}>
													Send Message
												</button>
											</div>
										</div>

									</Form>
								)}
							</Formik>

						</div> {/* form-wrapper END */}
						<div>
							{toggleState &&
								<div style={{
									"textAlign": "center",
									"marginBottom": "40px",
								}}>
									<h2>Thank you for contacting us.</h2>
								</div>
							}
						</div>
					</div> {/* main-contact-wrapper END */}
				</div> {/* main-about-content-block-wrapper END */}
			</div> {/* main-about-content-wrapper END */}

		</Fragment>
	)
}
