import { object, string, bool } from 'yup'

import PersonalInfo from './views/PersonalInfo'
import EmploymentGuarantor from './views/EmploymentGuarantor'
import CriminalHistoryEmergency from './views/CriminalHistoryEmergency'
import Acknowledgements from './views/Acknowledgements'

export default [
	{
		id: 'personal',
		component: PersonalInfo,
		initialValues: {
			applicantName: '',
			streetTemp: '',
			cityTemp: '',
			stateTemp: '',
			zipTemp: '',
			applicantTimeAtAddress: '',
			applicantEmail: '',
			phoneTemp: '',
			ssn: '',
			dob: '',
			licenseNumber: '',
			licenseState: '',
		},
		validationSchema: object().shape({
			applicantName: string().required('Applicant name is required'),
			applicantEmail: string().required('Applicant email is required').email('Must be a valid email address'),
			// streetTemp: string().required('Street is required'),
			// cityTemp: string().required('City is required'),
			// stateTemp: string().required('State is required'),
			// zipTemp: string().required('Zip is required')
			// 	.matches(/^[0-9]+$/, "Must be only digits")
			// 	.min(5, 'Must be exactly 5 digits')
			// 	.max(5, 'Must be exactly 5 digits')
		}),
	},
	{
		id: 'employment-guarantor',
		component: EmploymentGuarantor,
		initialValues: {
			employer: '',
			position: '',
			streetEmployment: '',
			cityEmployment: '',
			stateEmployment: '',
			zipEmployment: '',
			lengthOfEmployment: '',
			monthlyIncome: '',
			cosignerName: '',
			streetCosigner: '',
			cityCosigner: '',
			stateCosigner: '',
			zipCosigner: '',
			phoneCosigner: '',
			emailCosigner: '',
		},
		// validationSchema: object().shape({
		// 	companyName: string().required(),
		// }),

	},
	{
		id: 'criminal-history-emergency',
		component: CriminalHistoryEmergency,
		initialValues: {
			emergencyName: '',
			emergencyRelationship: '',
			emailEmergency: '',
			phoneEmergency: '',
			felonyBackground: '',
			misdemeanorBackground: '',
			evictedHistory: '',
			outstandingBalanceHistory: '',
		},
	},
	{
		id: 'acknowledgements',
		component: Acknowledgements,
		initialValues: {},
		validationSchema: object().shape({
			agreement: bool()
				.test('agreement', 'You must agree before you can submit your application', value => value === true)
				.required('You must agree before you can submit your application'),
		}),
	}
]
