import { FastField, useFormikContext } from 'formik'
import React, { Fragment } from 'react'

function EmploymentGuarantor() {
	const { errors, touched } = useFormikContext()

	return (
		<Fragment>
			<div id="segment-2" className="segment">
				<div id="employment-info" className="form-group">
					<h3>Employment Information</h3>

					<label htmlFor="employer">Employer</label>
					<FastField type="text" name="employer" id="employer" />
					<small className="error">
						{touched.employer && errors.employer}
					</small>

					<label htmlFor="position">Position</label>
					<FastField type="text" name="position" id="position" />
					<small className="error">
						{touched.position && errors.position}
					</small>


					{/* address-employment */}
					<div id="address-employment" className="form-group">
						<h4>Address of Employer</h4>

						<label htmlFor="streetEmployment">Street</label>
						<FastField type="text" name="streetEmployment" id="streetEmployment" />
						<small className="error">
							{touched.streetEmployment && errors.streetEmployment}
						</small>

						<label htmlFor="cityEmployment">City</label>
						<FastField type="text" name="cityEmployment" id="cityEmployment" />
						<small className="error">
							{touched.cityEmployment && errors.cityEmployment}
						</small>

						<div
							style={{
								display: "grid",
								gridTemplateColumns: "70px 120px 1fr",
								gridColumnGap: "10px"
							}}
						>
							<div>
								<label htmlFor="stateEmployment">State</label>
								<FastField as="select" name="stateEmployment" id="stateEmployment">
									<option value="">–</option>
									<option value="AL">AL</option>
									<option value="AK">AK</option>
									<option value="AR">AR</option>
									<option value="AZ">AZ</option>
									<option value="CA">CA</option>
									<option value="CO">CO</option>
									<option value="CT">CT</option>
									<option value="DC">DC</option>
									<option value="DE">DE</option>
									<option value="FL">FL</option>
									<option value="GA">GA</option>
									<option value="HI">HI</option>
									<option value="IA">IA</option>
									<option value="ID">ID</option>
									<option value="IL">IL</option>
									<option value="IN">IN</option>
									<option value="KS">KS</option>
									<option value="KY">KY</option>
									<option value="LA">LA</option>
									<option value="MA">MA</option>
									<option value="MD">MD</option>
									<option value="ME">ME</option>
									<option value="MI">MI</option>
									<option value="MN">MN</option>
									<option value="MO">MO</option>
									<option value="MS">MS</option>
									<option value="MT">MT</option>
									<option value="NC">NC</option>
									<option value="NE">NE</option>
									<option value="NH">NH</option>
									<option value="NJ">NJ</option>
									<option value="NM">NM</option>
									<option value="NV">NV</option>
									<option value="NY">NY</option>
									<option value="ND">ND</option>
									<option value="OH">OH</option>
									<option value="OK">OK</option>
									<option value="OR">OR</option>
									<option value="PA">PA</option>
									<option value="RI">RI</option>
									<option value="SC">SC</option>
									<option value="SD">SD</option>
									<option value="TN">TN</option>
									<option value="TX">TX</option>
									<option value="UT">UT</option>
									<option value="VT">VT</option>
									<option value="VA">VA</option>
									<option value="WA">WA</option>
									<option value="WI">WI</option>
									<option value="WV">WV</option>
									<option value="WY">WY</option>
								</FastField>
								<small className="error">
									{touched.stateEmployment && errors.stateEmployment}
								</small>
							</div>

							<div>
								<label htmlFor="zipEmployment">Zip</label>
								<FastField type="text" name="zipEmployment" id="zipEmployment" />
								<small className="error">
									{touched.zipEmployment && errors.zipEmployment}
								</small>
							</div>
						</div>

					</div>
					{/* address-employment END */}

					<label htmlFor="lengthOfEmployment">Length of Employment</label>
					<FastField type="text" name="lengthOfEmployment" id="lengthOfEmployment" />
					<small className="error">
						{touched.lengthOfEmployment && errors.lengthOfEmployment}
					</small>

					<label htmlFor="monthlyIncome">Monthly Income</label>
					<FastField type="text" name="monthlyIncome" id="monthlyIncome" />
					<small className="error">
						{touched.monthlyIncome && errors.monthlyIncome}
					</small>

				</div> {/* employment-info END */}

				<div id="guarantor-info" className="form-group">
					<h3>Cosigner Information</h3>
					<label htmlFor="cosignerName">Name of Cosigner</label>
					<FastField type="text" name="cosignerName" id="cosignerName" />
					<small className="error">
						{touched.cosignerName && errors.cosignerName}
					</small>

					{/* address-guarantor */}
					<div id="address-guarantor" className="form-group">
						<h4>Address of Cosigner</h4>

						<label htmlFor="streetCosigner">Street</label>
						<FastField type="text" name="streetCosigner" id="streetCosigner" />
						<small className="error">
							{touched.streetCosigner && errors.streetCosigner}
						</small>

						<label htmlFor="cityCosigner">City</label>
						<FastField type="text" name="cityCosigner" id="cityCosigner" />
						<small className="error">
							{touched.cityCosigner && errors.cityCosigner}
						</small>

						<div
							style={{
								display: "grid",
								gridTemplateColumns: "70px 120px 1fr",
								gridColumnGap: "10px"
							}}
						>
							<div>
								<label htmlFor="stateCosigner">State</label>
								<FastField as="select" name="stateCosigner" id="stateCosigner">
									<option value="">–</option>
									<option value="AL">AL</option>
									<option value="AK">AK</option>
									<option value="AR">AR</option>
									<option value="AZ">AZ</option>
									<option value="CA">CA</option>
									<option value="CO">CO</option>
									<option value="CT">CT</option>
									<option value="DC">DC</option>
									<option value="DE">DE</option>
									<option value="FL">FL</option>
									<option value="GA">GA</option>
									<option value="HI">HI</option>
									<option value="IA">IA</option>
									<option value="ID">ID</option>
									<option value="IL">IL</option>
									<option value="IN">IN</option>
									<option value="KS">KS</option>
									<option value="KY">KY</option>
									<option value="LA">LA</option>
									<option value="MA">MA</option>
									<option value="MD">MD</option>
									<option value="ME">ME</option>
									<option value="MI">MI</option>
									<option value="MN">MN</option>
									<option value="MO">MO</option>
									<option value="MS">MS</option>
									<option value="MT">MT</option>
									<option value="NC">NC</option>
									<option value="NE">NE</option>
									<option value="NH">NH</option>
									<option value="NJ">NJ</option>
									<option value="NM">NM</option>
									<option value="NV">NV</option>
									<option value="NY">NY</option>
									<option value="ND">ND</option>
									<option value="OH">OH</option>
									<option value="OK">OK</option>
									<option value="OR">OR</option>
									<option value="PA">PA</option>
									<option value="RI">RI</option>
									<option value="SC">SC</option>
									<option value="SD">SD</option>
									<option value="TN">TN</option>
									<option value="TX">TX</option>
									<option value="UT">UT</option>
									<option value="VT">VT</option>
									<option value="VA">VA</option>
									<option value="WA">WA</option>
									<option value="WI">WI</option>
									<option value="WV">WV</option>
									<option value="WY">WY</option>
								</FastField>
								<small className="error">
									{touched.stateCosigner && errors.stateCosigner}
								</small>
							</div>

							<div>
								<label htmlFor="zipCosigner">Zip</label>
								<FastField type="text" name="zipCosigner" id="zipCosigner" />
								<small className="error">
									{touched.zipCosigner && errors.zipCosigner}
								</small>
							</div>

							<div>

							</div>
						</div>
					</div>
					{/* address-guarantor END */}

					<label htmlFor="phoneCosigner">Phone</label>
					<FastField type="text" name="phoneCosigner" id="phoneCosigner" />
					<small className="error">
						{touched.phoneCosigner && errors.phoneCosigner}
					</small>

					<label htmlFor="emailCosigner">Email Address</label>
					<FastField type="text" name="emailCosigner" id="emailCosigner" />
					<small className="error">
						{touched.emailCosigner && errors.emailCosigner}
					</small>
				</div> {/* guarantor-info END */}
			</div>

		</Fragment>
	)
}

export default EmploymentGuarantor
