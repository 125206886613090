import React, { Component } from 'react'

import { BrowserRouter, Route, Switch, /* Link */ } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToTop from 'react-router-scroll-top';

/* Custom components */
import Header from './Header';
import MenuPopup from './MenuPopup'
import Footer from './Footer'
import Home from './Home';
import Listings from './Listings';
import AboutUs from './AboutUs';
import WorkWithUs from './WorkWithUs';
import DownloadForms from './DownloadForms';
import ApplicationForm from './ApplicationForm';
import NotFound from './NotFound';

/* SCSS */
import '../scss/main.scss';

/* Assets */
import site_data from '../data/site-meta.json'; // Site meta data defined in JSON file
import GoogleFontLoader from 'react-google-font-loader';

/* Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core'
/* -- Solid (fas) */
import { faTimes, faBars, faCheckCircle, faTimesCircle, faCoffee, faHeart, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faClipboardList, faFilePdf, faFileWord, faHomeHeart, faEnvelopeOpenDollar, faMoneyCheckEditAlt, faMapMarkedAlt, faBedAlt, faDogLeashed, faFileDownload, faCouch, faParking, 	faBusAlt,	faGarageCar, faToilet, faRulerTriangle,
 } from '@fortawesome/pro-duotone-svg-icons';
/* -- Brand (fab) */
import { faFacebookSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Residents from './Residents';

/* -- library for global use */
library.add(
	faFacebookSquare,
	faInstagramSquare,
	faLinkedin,
	faTimes,
	faBars,
	faClipboardList,
	faFilePdf,
	faFileWord,
	faMapMarkedAlt,
	faHomeHeart,
	faBedAlt,
	faDogLeashed,
	faFileDownload,
	faMoneyCheckEditAlt,
	faEnvelopeOpenDollar,
	faCheckCircle,
	faTimesCircle,
	faCoffee,
	faHeart,
	faSearch,
	faCouch,
	faParking,
	faBusAlt,
	faGarageCar,
	faToilet,
	faRulerTriangle,
)


export default class Router extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isPopupOpen: false,
		}
	}
	handleMenuClick = () => {
		this.setState(state => ({
			isPopupOpen: !state.isPopupOpen
		}));
	}


	render() {
		return (
			<BrowserRouter>
				<ScrollToTop>
				<Helmet>
					{/*
						Site meta data defaults
						Use Helmet component in child components to override any tags
					*/}
					<title>{site_data.title}</title>
					<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
					<meta name="description" content={site_data.description} />
					<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
					<meta name="theme-color" content={site_data.theme_color} />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
					<meta name="apple-mobile-web-app-title" content={site_data.title} />
					<meta name="application-name" content={site_data.title} />
					<link rel="canonical" href="https://awaypropertymanagement.com/" />
					<link rel="dns-prefetch" href="//cms.awaypropertymanagement.com" />
					<link href="https://cms.awaypropertymanagement.com" rel="preconnect" crossorigin />
				</Helmet>

				{/* Loads Google Fonts */}
				<GoogleFontLoader
					fonts={[
						{
							font: 'Barlow',
							weights: [100, 400, 500, 700, 800],
							/*
								thin: 100
								regular: 400
								medium: 500
								extrabold: 800
							*/
						}
					]}
				/>

				<div id="wrapper"
						className={(this.state.isPopupOpen ? 'no-scroll' : '')}
				>
					{/* Created Header component */}
					<Header />
					<button
						id="mobile-menu-b"
						aria-label="Toggle navigation menu"
						className={"icon-button " + (this.state.isPopupOpen ? 'open' : '')}
						onClick={this.handleMenuClick}
					>
						{this.state.isPopupOpen ? (
							<FontAwesomeIcon size="2x" icon={['fas', 'times']} />
						) : (
							<FontAwesomeIcon size="2x" icon={['fas', 'bars']} />
						)}
					</button>
					<MenuPopup isOpen={this.state.isPopupOpen} handleMenuClick={this.handleMenuClick} />
					<main>
						{/* Main section of the page */}



						<Switch>
							{/*
								Switches are blocks of content that load components determined by Routes
								- 'exact' matches only when URL matches 'path' exactly
								- 'path' is the pattern for matching
								- 'component' is the component loaded in place of the Switch component. Any components used in Routes must be imported first.
								- Switch tests each Route in order. If there is a match, the component is loaded and Switch stops checking for matches.
							*/}
							<Route exact path="/" component={Home} />
							<Route exact path="/listings" component={Listings} />
							<Route exact path="/about-us" component={AboutUs} />
							<Route exact path="/residents" component={Residents} />
								<Route exact path="/work-with-us" component={WorkWithUs} />
							<Route exact path="/download-forms" component={DownloadForms} />
							<Route path="/apply/:locationId" component={ApplicationForm} />
							<Route component={NotFound} />
						</Switch>
						{/* <p>This is some middle content</p>
						<Switch> */}
							{/* This switch only loads the Bobs component if URL is '/'. There is no fallback Route. */}
							{/* <Route exact path="/" component={Bobs} />
						</Switch> */}

						{/* <p>This is some bottom content</p> */}
					</ main>
					{/* Added footer component */}
					<Footer></Footer>
				</div>
				</ScrollToTop>
			</BrowserRouter>
		)
	}
}
