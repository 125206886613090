import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';

export default class ImageGalleryModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			photoIndex: 0,
			isOpen: false,
		};
		this.closeGallery = this.closeGallery.bind(this);

	}
	closeGallery() {
		this.setState({ isOpen: false })
		this.props.closeModal();
	}
	render() {
		const images = this.props.images.map(obj => {
			return obj.sizes['1536x1536'];
		})
		return (
			<div id="image-gallery-modal">
					<Lightbox
						mainSrc={images[this.state.photoIndex]}
						nextSrc={images[(this.state.photoIndex + 1) % images.length]}
						prevSrc={images[(this.state.photoIndex + images.length - 1) % images.length]}
					onCloseRequest={this.closeGallery}
						onMovePrevRequest={() =>
							this.setState({
								photoIndex: (this.state.photoIndex + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								photoIndex: (this.state.photoIndex + 1) % images.length,
							})
						}
					/>
			</div>
		)
	}
}
